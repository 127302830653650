import { Collection } from "../../solsteads-api";

export function getFrameOptions(collections?: Collection[]) {
  return [
    {
      name: "Default",
      value: "default",
      disabled: false,
    },
    ...Object.values(FRAMES_MAP)
      .filter((frame) => !DEFAULT_FRAME_IDS.includes(frame.id))
      .map((frame) => {
        return {
          name: frame.name,
          value: frame.id,
          // disabled if the required collection is not in the passed in collections or if collections isn't an array
          disabled: collections
            ? !collections.find(
                (c) =>
                  c.family === frame.requiredCollection.family &&
                  c.name === frame.requiredCollection.name
              )
            : true,
        };
      }),
    {
      name: "None",
      value: "none",
      disabled: false,
    },
  ];
}

export function getFrameOrDefault(frameId: string, dfault: string): Frame {
  const frameKey = frameId === "default" ? dfault : frameId;
  return FRAMES_MAP[frameKey];
}

export type Frame = {
  id: string;
  imageFilename: string;
  name: string;
  padding: {
    x: number;
    y: number;
  };
  requiredCollection?: Collection;
};

const ROUNDED_FRAME_PADDING = { x: 23, y: 23 };
const SHUTTERED_FRAME_PADDING = { x: 55, y: 23 };

export const FRAMES_MAP: { [key: string]: Frame } = {
  crystal: {
    id: "crystal",
    imageFilename: "frame-crystal.png",
    name: "Crystal",
    padding: { x: 30, y: 30 },
  },
  glass: {
    id: "glass",
    imageFilename: "frame-glass.png",
    name: "Glass",
    padding: { x: 22, y: 22 },
  },
  gold: {
    id: "gold",
    imageFilename: "frame-gold.png",
    name: "Gold",
    padding: { x: 23, y: 23 },
  },
  occult: {
    id: "occult",
    imageFilename: "frame-occult.png",
    name: "Occult",
    padding: { x: 22, y: 22 },
  },
  "rounded-blue": {
    id: "rounded-blue",
    imageFilename: "frame-rounded-blue.png",
    name: "Rounded Blue",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-dark-green": {
    id: "rounded-dark-green",
    imageFilename: "frame-rounded-dark-green.png",
    name: "Rounded Dark Green",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-green": {
    id: "rounded-green",
    imageFilename: "frame-rounded-green.png",
    name: "Rounded Green",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-lavender": {
    id: "rounded-lavender",
    imageFilename: "frame-rounded-lavender.png",
    name: "Rounded Lavender",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-pale-green": {
    id: "rounded-pale-green",
    imageFilename: "frame-rounded-pale-green.png",
    name: "Rounded Pale Green",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-red": {
    id: "rounded-red",
    imageFilename: "frame-rounded-red.png",
    name: "Rounded Red",
    padding: ROUNDED_FRAME_PADDING,
  },
  "rounded-yellow": {
    id: "rounded-yellow",
    imageFilename: "frame-rounded-yellow.png",
    name: "Rounded Yellow",
    padding: ROUNDED_FRAME_PADDING,
  },
  "shuttered-blue": {
    id: "shuttered-blue",
    imageFilename: "frame-shuttered-blue.png",
    name: "Shuttered Blue",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-dark-green": {
    id: "shuttered-dark-green",
    imageFilename: "frame-shuttered-dark-green.png",
    name: "Shuttered Dark Green",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-green": {
    id: "shuttered-green",
    imageFilename: "frame-shuttered-green.png",
    name: "Shuttered Green",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-gmoot": {
    id: "shuttered-gmoot",
    imageFilename: "frame-shuttered-gmoot.png",
    name: "Shuttered Gmoot",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-lavender": {
    id: "shuttered-lavender",
    imageFilename: "frame-shuttered-lavender.png",
    name: "Shuttered Lavender",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-pale-green": {
    id: "shuttered-pale-green",
    imageFilename: "frame-shuttered-pale-green.png",
    name: "Shuttered Pale Green",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-red": {
    id: "shuttered-red",
    imageFilename: "frame-shuttered-red.png",
    name: "Shuttered Red",
    padding: SHUTTERED_FRAME_PADDING,
  },
  "shuttered-yellow": {
    id: "shuttered-yellow",
    imageFilename: "frame-shuttered-yellow.png",
    name: "Shuttered Yellow",
    padding: SHUTTERED_FRAME_PADDING,
  },
  slime: {
    id: "slime",
    imageFilename: "frame-gold.png",
    name: "Gold",
    padding: { x: 23, y: 23 },
  },
  // vvvvvv Collab Frames Start Here vvvvvv
  "skeleton-crew-bones": {
    id: "skeleton-crew-bones",
    imageFilename: "frame-skeleton-crew-bones.png",
    name: "Bones (Skeleton Crew)",
    padding: { x: 26, y: 26 },
    requiredCollection: {
      family: "Skeleton Crew Skulls",
      name: "First Shift",
    },
  },
  "artificial-irrelevants-cube": {
    id: "artificial-irrelevants-cube",
    imageFilename: "frame-artificial-irrelevants-cube.png",
    name: "Cube (Artificial Irrelevants)",
    padding: { x: 34, y: 34 },
    requiredCollection: {
      family: "Artificial Irrelevants",
      name: "Artificial Irrelevants",
    },
  },
  "shattered-marble-marble": {
    id: "shattered-marble-marble",
    imageFilename: "frame-shattered-marble-marble.png",
    name: "Marble (Shattered Marble)",
    padding: ROUNDED_FRAME_PADDING,
    requiredCollection: {
      family: "Shattered Marble",
      name: "Shattered Marble",
    },
  },
  "grim-syndicate-grim": {
    id: "grim-syndicate-grim",
    imageFilename: "frame-grim-syndicate-grim.png",
    name: "Grim (Grim Syndicate)",
    padding: ROUNDED_FRAME_PADDING,
    requiredCollection: {
      family: "Grim Syndicate",
      name: "Grim Syndicate",
    },
  },
  "solsteads-solsteads-keepsakes": {
    id: "solsteads-solsteads-keepsakes",
    imageFilename: "frame-winter-2021.png",
    name: "Icy (Winter Steads Keepsake)",
    padding: ROUNDED_FRAME_PADDING,
    requiredCollection: {
      family: "Solsteads",
      name: "Solsteads Keepsakes",
    },
  },
  "famous-fox-federation-tails": {
    id: "famous-fox-federation-tails",
    imageFilename: "frame-famous-foxes.png",
    name: "Fox Tails (Famous Foxes & TFF)",
    padding: ROUNDED_FRAME_PADDING,
    requiredCollection: {
      family: "Famous Fox Federation",
      name: "Famous Fox Federation",
    },
  }
};

export const DEFAULT_FRAME_IDS = [
  "crystal",
  "gold",
  "glass",
  "occult",
  "rounded-blue",
  "rounded-dark-green",
  "rounded-green",
  "rounded-lavender",
  "rounded-pale-green",
  "rounded-red",
  "rounded-yellow",
  "shuttered-blue",
  "shuttered-dark-green",
  "shuttered-green",
  "shuttered-gmoot",
  "shuttered-lavender",
  "shuttered-pale-green",
  "shuttered-red",
  "shuttered-yellow",
  "slime",
];
