import NextLink from "next/link";
import styled from "styled-components";

const SolsteadsLink = styled.span`
  color: #0f1954;
  :hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
`;

type LinkProps = {
  href: string;
  passHref?: boolean;
  newTab?: boolean;
};

export function Link({
  children,
  href,
  passHref = false,
  newTab = false,
}: React.PropsWithChildren<LinkProps>) {
  return (
    <SolsteadsLink>
      {newTab ? (
        <a target="_blank" rel="noreferrer" href={href}>
          {children}
        </a>
      ) : (
        <NextLink href={href} passHref={passHref}>
          {children}
        </NextLink>
      )}
    </SolsteadsLink>
  );
}
