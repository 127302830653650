import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import { SimpleOwnerPill } from "./SimpleOwnerPill";
import { useRouter } from "next/router";
import { RequestAStayButton } from "./RequestAStayButton";
import { Guest, Metadata, Solstead } from "../../../../solsteads-api";
import { useGetTokenMetadata } from "../../../hooks";
import VacantImage from "../../../../public/images/img_vacant.png";
import cloudflareLoader from "../../../imageLoader";

type HousematesProps = {
  guests: Guest[];
  solstead: Solstead;
  small?: boolean;
};

export default function Housemates(props: HousematesProps) {
  const guests = props?.guests ?? [];
  return (
    <Grid
      container
      spacing={{ xs: 4, md: 6 }}
      columns={{ xs: 4, sm: 8, md: 9, lg: 12 }}
      justifyContent={"center"}
    >
      {guests.length ? (
        guests.map((guest, index) => {
          return (
            <Grid key={index} item xs={4}>
              <Housemate
                solstead={props.solstead}
                guest={guest}
                small={props.small}
              />
            </Grid>
          );
        })
      ) : (
        <Grid item>
          <Typography sx={{ marginTop: "2rem" }} variant="h5">
            This Citizen doesn't have any housemates.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

type HousemateProps = {
  guest: Guest;
  solstead: Solstead;
  small: boolean;
};

export function Housemate({ guest, solstead, small }: HousemateProps) {
  const router = useRouter();
  const isVacant = !guest;

  const { data, error, isLoading } = useGetTokenMetadata(
    {
      tokenAddress: guest?.guestToken?.address,
    },
    {
      enabled: !isVacant,
    }
  );
  const metadata = data as Metadata;

  return (
    <Grid container flexDirection={"column"} alignItems={"center"}>
      {isVacant || isLoading ? (
        <Image
          loader={cloudflareLoader}
          src={VacantImage}
          width={small ? 150 : 250}
          height={small ? 150 : 250}
        />
      ) : (
        <a
          href={
            isVacant
              ? null
              : `${router.asPath}/${metadata.name
                  .split(" ")[1]
                  .split("#")
                  .join("-")}`
          }
        >
          <Image
            src={metadata.properties.files.find((file) => file.cdn).uri}
            width={small ? 150 : 250}
            height={small ? 150 : 250}
          />
        </a>
      )}
      <Box marginTop={"2rem"}>
        {isVacant ? (
          <RequestAStayButton solstead={solstead} />
        ) : (
          <SimpleOwnerPill
            twitterHandle={guest.guestTwitterHandle}
            walletAddress={guest.guestWallet.address}
          />
        )}
      </Box>
    </Grid>
  );
}

export { Housemates };
