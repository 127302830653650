import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useAuth } from ".";
import { GuestRequest } from "../../solsteads-api";
import { getSolsteadsApiClient } from "../utils";

type AddGuestRequestParams = {
  guestWalletAddress: string;
  guestTokenAddress: string;
  solsteadWalletAddress: string;
  solsteadTokenAddress: string;
};

export function useAddGuestRequest(options?: UseMutationOptions) {
  const queryClient = useQueryClient();
  const { authToken } = useAuth();
  const solsteadsApi = getSolsteadsApiClient(authToken);

  return useMutation(
    ({
      guestTokenAddress,
      guestWalletAddress,
      solsteadWalletAddress,
      solsteadTokenAddress,
    }: AddGuestRequestParams) => {
      return solsteadsApi.addGuestRequest(
        guestWalletAddress,
        guestTokenAddress,
        {
          solsteadWalletAddress,
          solsteadTokenAddress,
        }
      );
    },
    {
      onSuccess: async (_, params: AddGuestRequestParams) => {
        const guestQueryKey = [
          "users",
          params.guestWalletAddress,
          "tokens",
          params.guestTokenAddress,
          "guest-requests",
        ];
        const solsteadQueryKey = [
          "users",
          params.solsteadWalletAddress,
          "tokens",
          params.solsteadTokenAddress,
          "guest-requests",
        ];
        await queryClient.cancelQueries(guestQueryKey);
        await queryClient.cancelQueries(solsteadQueryKey);

        const previousSolsteadData =
          (queryClient.getQueryData(solsteadQueryKey) as GuestRequest[]) || [];

        const newGuestData = [
          {
            guestToken: {
              blockchain: "sol",
              address: params.guestTokenAddress,
            },
            guestWallet: {
              blockchain: "sol",
              address: params.guestWalletAddress,
            },
            solsteadToken: {
              blockchain: "sol",
              address: params.solsteadTokenAddress,
            },
            solsteadWallet: {
              blockchain: "sol",
              address: params.solsteadWalletAddress,
            },
          },
        ];
        const newSolsteadData = [
          ...previousSolsteadData,
          {
            guestToken: {
              blockchain: "sol",
              address: params.guestTokenAddress,
            },
            guestWallet: {
              blockchain: "sol",
              address: params.guestWalletAddress,
            },
            solsteadToken: {
              blockchain: "sol",
              address: params.solsteadTokenAddress,
            },
            solsteadWallet: {
              blockchain: "sol",
              address: params.solsteadWalletAddress,
            },
          },
        ];

        queryClient.setQueryData(guestQueryKey, newGuestData);
        queryClient.setQueryData(solsteadQueryKey, newSolsteadData);
        return undefined;
      },
    }
  );
}
