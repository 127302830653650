import React from "react";
import Image from "next/image";
import styled from "styled-components";
import UserIcon from "../../../../public/images/icon_user.svg";
import TwitterIcon from "../../../../public/images/icon_twitter.svg";
import { shortenPublicKey } from "../../../utils";
import cloudflareLoader from "../../../imageLoader";

const OwnerPillContainer = styled.div`
  align-items: center;
  display: flex;
  color: #0f1954;
  border: 2px solid #0f1954;
  border-radius: 10px;
  font-family: abodeFontBold;
  padding: 0.6rem 1rem;
  :hover {
    cursor: pointer;
  }
`;

const OwnerPillText = styled.span`
  height: 20px;
  margin-left: 10px;
`;

type SimpleOwnerPillProps = {
  twitterHandle: string;
  walletAddress: string;
};

export function SimpleOwnerPill({
  twitterHandle,
  walletAddress,
}: SimpleOwnerPillProps) {
  const explorerLink = `https://explorer.solana.com/address/${walletAddress}/tokens`;
  const twitterLink = `https://twitter.com/${twitterHandle}`;
  const walletDisplayText = walletAddress
    ? shortenPublicKey(walletAddress)
    : "";
  const twitterDisplayText = twitterHandle ? `@${twitterHandle}` : "";

  return (
    <a
      href={twitterHandle ? twitterLink : explorerLink}
      target="_blank"
      rel="noreferrer"
    >
      <OwnerPillContainer>
        <Image
          loader={cloudflareLoader}
          src={twitterHandle ? TwitterIcon : UserIcon}
          height="20"
          width="20"
          alt=""
        />
        <OwnerPillText>
          {twitterHandle ? twitterDisplayText : walletDisplayText}
        </OwnerPillText>
      </OwnerPillContainer>
    </a>
  );
}
