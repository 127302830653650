import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Token } from "../../../../solsteads-api";

type CitizenChooserProps = {
  citizenTokens: Token[];
  onSelect: (token: Token) => void;
};

export function CitizenChooser({
  citizenTokens,
  onSelect,
}: CitizenChooserProps) {
  return (
    <ImageList cols={3} gap={16}>
      {citizenTokens.map((token) => (
        <ImageListItem
          sx={{ cursor: "pointer" }}
          key={token.address}
          onClick={() => {
            onSelect(token);
          }}
        >
          <img
            src={`${
              token.metadata.properties.files.find((file) => file.cdn).uri
            }`}
            alt={token.metadata.name}
            loading="lazy"
          />
          <ImageListItemBar
            title={token.metadata.name}
            position="top"
            sx={{ height: "2rem" }}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
