import { useRouter } from "next/router";
import * as React from "react";
import { Solstead } from "../../../../solsteads-api";
import { Button } from "../../../components/material";
import { useAuth } from "../../../hooks";
import { RequestAStayModal } from "./RequestAStayModal";

export function RequestAStayButton({ solstead }: { solstead: Solstead }) {
  const { isSignedIn } = useAuth();
  const router = useRouter();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (!isSignedIn) {
      router.push(`/sign-in`);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        Request A Stay
      </Button>
      <RequestAStayModal
        solstead={solstead}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
