import Image from "next/image";
import React from "react";
import styled from "styled-components";
import { Frame } from "../../../constants/frames";
import { NFTImage } from "./NFTImage";
import cloudflareLoader from "../../../imageLoader";

const ArtworkContainer = styled.div`
  margin: 0.2rem;
  position: relative;
`;

export type FramedImageProps = {
  frame: Frame;
  imgURL?: string;
};

export function FramedImage({ frame, imgURL }: FramedImageProps) {
  const [aspectRatio, setAspectRatio] = React.useState(1);
  const onImageLoad = (img) => {
    const aRatio = img.offsetWidth / img.offsetHeight;
    setAspectRatio(aRatio);
  };

  const isAspectRatioNearOne = 0.7 < aspectRatio && aspectRatio < 1.2;
  const framePaddingString = frame
    ? `${frame.padding.y}px ${frame.padding.x * aspectRatio}px`
    : "0";

  return (
    <ArtworkContainer style={{ padding: framePaddingString }}>
      <NFTImage onLoad={onImageLoad} src={imgURL} alt="NFT Image" />
      {frame && isAspectRatioNearOne ? (
        <Image
          loader={cloudflareLoader}
          alt=""
          src={`/frames/${frame.imageFilename}`}
          layout="fill"
        />
      ) : null}
    </ArtworkContainer>
  );
}
