import { styled } from "@mui/material/styles";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";

const Root = styled("span")(`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #97a4c4;
    border-radius: 12px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #efe4e1;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 1px 8px rgba(0,0,0,0.25);
  }

  &.${switchUnstyledClasses.checked} { 
    .${switchUnstyledClasses.thumb} {
      left: 25px;
      top: 3px;
      background-color: #efe4e1;
    }

    .${switchUnstyledClasses.track} {
      background: #0f1954;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }`);

export function Switch(props) {
  return <SwitchUnstyled component={Root} {...props} />;
}
