import * as React from "react";
import Modal from "@mui/material/Modal";
import { Button, Paper, Typography } from "../../../components/material";
import { useMediaQuery } from "@react-hook/media-query";
import { useGetMySolsteads } from "../../../hooks/useGetMySolsteads";
import { useAuth } from "../../../hooks";
import { CitizenChooser } from "./CitizenChooser";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Solstead, Token } from "../../../../solsteads-api";
import { Box } from "@mui/system";
import { getSolsteadConstants } from "../../../constants";
import { useRouter } from "next/router";
import { useAddGuestRequest } from "../../../hooks/useAddGuestRequest";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} {...props} ref={ref} variant="filled" />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type RequestAStayModalProps = {
  solstead: Solstead;
  open: boolean;
  handleClose: () => void;
};

export function RequestAStayModal({
  solstead,
  open,
  handleClose,
}: RequestAStayModalProps) {
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorShown, setErrorShown] = React.useState(false);
  const [stayRequested, setStayRequested] = React.useState(false);
  const { walletPublicKey } = useAuth();
  const isSmallScreen = useMediaQuery("only screen and (max-width: 960px)");
  const { data, isLoading } = useGetMySolsteads({
    walletAddress: walletPublicKey,
  });
  const { mutate: addGuestRequest, isError, error } = useAddGuestRequest();

  const router = useRouter();
  const token = getSolsteadConstants(router.query.solsteadsId as string).token;

  function handleCitizenSelect(token: Token) {
    setErrorShown(false);
    addGuestRequest({
      guestTokenAddress: token.address,
      guestWalletAddress: walletPublicKey,
      solsteadTokenAddress: solstead.token.address,
      solsteadWalletAddress: solstead.owner.address,
    });
    setStayRequested(true);
  }

  if (isError && !errorShown) {
    setErrorShown(true);
    setErrorOpen(true);
  }

  const citizenTokens = data?.citizenTokens ?? [];

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            width: "calc(100vw - 4rem)",
            maxWidth: "650px",
            maxHeight: "calc(90vh - 4rem)",
            overflowY: "auto",
            padding: isSmallScreen ? "1rem" : "2rem 3rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {stayRequested ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">
                Your request has been sent. Make sure you've connected your
                Twitter so the owner knows who is requesting the stay.
              </Typography>
              <Button
                sx={{ marginTop: "2rem" }}
                variant="outlined"
                onClick={() => {
                  setStayRequested(false);
                  handleClose();
                }}
              >
                Close
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="h3">Request A Stay</Typography>
              <Typography sx={{ marginTop: "2rem" }} variant="h5">
                Choose A Citizen
              </Typography>
              {isLoading ? (
                <LoadingSpinner />
              ) : citizenTokens.length ? (
                <CitizenChooser
                  citizenTokens={citizenTokens}
                  onSelect={handleCitizenSelect}
                />
              ) : (
                <Box marginTop={"2rem"} marginBottom={"2rem"}>
                  <Typography variant="body1">
                    You need a Citizen to Request A Stay in a Solstead. Buy one
                    on{" "}
                    <strong>
                      <a
                        href="https://magiceden.io/marketplace/citizens_by_solsteads"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        Magic Eden.
                      </a>
                    </strong>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Paper>
      </Modal>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => {
          setErrorOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorOpen(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          You can only submit one guest request per Citizen at a time. You
          cancel any existing requests from your My Solsteads page.
        </Alert>
      </Snackbar>
    </>
  );
}
