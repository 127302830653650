import Image from "next/image";
import styled from "styled-components";
import SpinnerImage from "../../public/images/img_loading_spinner.svg";

const SmallContainer = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
`;

const Container = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
`;

const PageSpinnerContainer = styled.div`
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
`;

type LoadingSpinnerProps = {
  isPageSpinner?: boolean;
  size?: "small";
};

export function LoadingSpinner({
  isPageSpinner = false,
  size,
}: LoadingSpinnerProps) {
  if (isPageSpinner) {
    return (
      <PageSpinnerContainer>
        <Image alt="Loading" src={SpinnerImage} />
      </PageSpinnerContainer>
    );
  }

  if (size === "small") {
    return (
      <SmallContainer>
        <Image alt="Loading" src={SpinnerImage} height={50} width={50} />
      </SmallContainer>
    );
  }

  return (
    <Container>
      <Image alt="Loading" src={SpinnerImage} />
    </Container>
  );
}
