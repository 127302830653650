import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from ".";
import { MySolsteadsResponse } from "../../solsteads-api";
import { getSolsteadsApiClient } from "../utils";

export function useGetMySolsteads(
  params: { walletAddress: string },
  options?: UseQueryOptions
) {
  const { authToken } = useAuth();
  const solsteadsApi = getSolsteadsApiClient(authToken);

  return useQuery<MySolsteadsResponse, Error>(
    ["my-solsteads", params.walletAddress],
    async () => {
      try {
        const response = await solsteadsApi.getMySolsteads(
          params.walletAddress
        );
        return response.data;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },
    {
      ...options,
      staleTime: Infinity,
      retry: true,
    }
  );
}
