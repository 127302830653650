import { useState } from "react";
import styled from "styled-components";

const DEFAULT_IMAGE_HEIGHT = 225;

const ImagePlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: grey;
  height: ${`${DEFAULT_IMAGE_HEIGHT}px`};
  width: ${`${DEFAULT_IMAGE_HEIGHT}px`};

  @keyframes breathe {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.6;
    }
  }

  animation: breathe 2s infinite;
`;

const Container = styled.div`
  position: relative;
  line-height: 0;
`;

const Img = styled.img``;

type NFTImageProps = {
  alt?: string;
  height?: number;
  onError?: (event: unknown) => void;
  onLoad?: (event: unknown) => void;
  src: string;
  width?: number;
};

export const NFTImage = ({
  alt,
  height,
  onError,
  onLoad,
  src,
  width,
}: NFTImageProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  if (error) {
    return null;
  }

  return (
    <Container
      style={
        loading
          ? {
              minHeight: `${DEFAULT_IMAGE_HEIGHT}px`,
              minWidth: `${DEFAULT_IMAGE_HEIGHT}px`,
            }
          : {}
      }
    >
      {loading ? <ImagePlaceholder /> : null}
      <Img
        style={loading ? { visibility: "hidden" } : {}}
        alt={alt}
        height={height}
        onError={(e) => {
          setError(true);
          onError(e);
        }}
        onLoad={({ target: img }) => {
          setLoading(false);
          onLoad(img);
        }}
        src={src}
        width={width}
      />
    </Container>
  );
};

NFTImage.defaultProps = {
  alt: "",
  height: DEFAULT_IMAGE_HEIGHT,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLoad: () => {},
  width: undefined,
};
