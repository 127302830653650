import { useQuery, UseQueryOptions } from "react-query";
import { getSolsteadConstants } from "../constants";
import { getSolsteadsApiClient } from "../utils";

export function useGetSolstead(
  params: { solsteadsId: string },
  options?: UseQueryOptions
) {
  const solsteadsApi = getSolsteadsApiClient();

  const solsteadConstants = getSolsteadConstants(params.solsteadsId);
  if (!solsteadConstants) {
    return undefined;
  }

  return useQuery(
    ["solsteads", params.solsteadsId],
    async () => {
      try {
        const response = await solsteadsApi.getSolstead(params.solsteadsId);
        return response.data.solstead;
      } catch (err) {
        return {
          ...solsteadConstants,
        };
      }
    },
    {
      ...options,
      staleTime: Infinity,
      placeholderData: {
        ...solsteadConstants,
      },
      retry: true,
    }
  );
}
